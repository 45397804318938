// import ProfileThumbnail from "../js/ProfileThumbnail.js";

export default class Global
{
    constructor (
        public button_menu = <HTMLButtonElement>document.getElementById("button_menu"),
        public nav_menu    = <HTMLElement>document.getElementById("nav_menu"),
    )
    {
        this.button_menu.addEventListener(
            "click",
            () =>
            {
                this.nav_menu.classList.toggle("open");
                this.button_menu.classList.add("menu-open");
            },
        );

        this.nav_menu.addEventListener(
            "click",
            (ev) =>
            {
                if ((
                        <HTMLElement>ev.target
                    ).nodeName === "NAV" || (
                        <HTMLElement>ev.target
                    ).id === "btn_menu_close")
                {
                    this.nav_menu.classList.remove("open");
                    this.button_menu.classList.remove("menu-open");
                }
            },
        );

        // ProfileThumbnail.display();
    }
}
